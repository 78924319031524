import navigationService from 'src/utils/navigationService'

export const navigateParams = (screens: string[], lastParam?: object, withoutHistory?: boolean) =>
  screens.reduceRight((prev, current, i) => {
    const defaultScreen = {
      screen: current,
    }

    if (withoutHistory) {
      // @ts-ignore
      defaultScreen.initial = false
    }

    if (i === screens.length - 1) {
      if (lastParam) {
        return {
          ...defaultScreen,
          ...lastParam,
        }
      }

      return defaultScreen
    }

    return {
      ...defaultScreen,
      params: { ...prev },
    }
  }, {})

export const navigateToApp = (screens = {}) =>
  navigationService.navigate('App', {
    ...screens,
  })

export const navigateToAppScreen = (screens: string[], lastParam?: object) =>
  navigateToApp(navigateParams(screens, lastParam))

export const navigateToScreenAfterScreen = (screens: string[], openView: string, lastParam?: object) =>
  navigateToApp(navigateParams(screens, { params: { openView, ...(lastParam || {}) } }, true))

export const navigateToAppScreenWithoutHistory = (screens: string[], lastParam?: object) =>
  navigateToApp(navigateParams(screens, lastParam || undefined, true))
