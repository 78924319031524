import gql from 'graphql-tag'

export default gql`
  mutation Mutation($update: UpdateSessionInfoDto!) {
    updateSessionInfo(update: $update) {
      sessionOrigin
      userId
      appVersion
      brand
      browserName
      browserVersion
      createdAt
      id
      model
      os
      osVersion
      updatedAt
    }
  }
`

export type UpdateSessionInfoDto = {
  update: {
    sessionOrigin: SessionOrigin
    userId: string
    appVersion: string
    brand: string
    model: string
    os: string
    osVersion: string
  }
}

export enum SessionOrigin {
  EDUCATOR_APP = 'EDUCATOR_APP',
  EDUCATOR_PORTAL = 'EDUCATOR_PORTAL',
}
