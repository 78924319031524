import { Platform } from 'react-native'
import { UAParser } from 'ua-parser-js'
import { useMutation } from '@apollo/client'
import AsyncStorage from '@react-native-async-storage/async-storage'
import DeviceInfo from 'src/utils/webAdapters/DeviceInfoAdapter'

import { SessionOrigin } from 'src/graphql/mutations/updateSessionInfo'
import { UpdateSessionInfo, UpdateSessionInfoDto } from 'src/graphql/mutations'
import { useAppSelector } from 'src/hooks/reduxHooks'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../package.json')

const STORAGE_KEY = 'sessionInfo'

const useSessionInfo = () => {
  const appVersion = version
  const userId = useAppSelector(state => state.user.id)

  const isWeb = Platform.OS === 'web'

  const [updateSessionInfo] = useMutation<UpdateSessionInfoDto>(UpdateSessionInfo)

  // Native app information
  let brand = ''
  let model = ''
  let osVersion = ''
  let os = ''

  // Web browser information
  let browserName = ''
  let browserVersion = ''

  if (isWeb) {
    const parser = new UAParser()
    const result = parser.getResult()
    browserName = result.browser.name || ''
    browserVersion = result.browser.version || ''
  } else {
    brand = DeviceInfo.getBrand() || ''
    model = DeviceInfo.getModel() || ''
    osVersion = DeviceInfo.getSystemVersion() || ''
    os = DeviceInfo.getSystemName() || ''
  }

  const getStoredSessionInfo = async () => {
    const storedSessionInfo = isWeb ? localStorage.getItem(STORAGE_KEY) : await AsyncStorage.getItem(STORAGE_KEY)

    return storedSessionInfo ? JSON.parse(storedSessionInfo) : {}
  }

  const storeSessionInfo = async (data: object) => {
    const dataString = JSON.stringify(data)
    if (isWeb) {
      localStorage.setItem(STORAGE_KEY, dataString)
    } else {
      await AsyncStorage.setItem(STORAGE_KEY, dataString)
    }
  }

  const isSessionInfoEqual = (storedData: object, newData: object) => {
    return JSON.stringify(storedData) === JSON.stringify(newData)
  }

  const handleUpdateSessionInfo = async () => {
    const newSessionInfo = isWeb
      ? {
          appVersion,
          browserName,
          browserVersion,
          sessionOrigin: SessionOrigin.EDUCATOR_PORTAL,
          userId,
        }
      : {
          appVersion,
          brand,
          model,
          os,
          osVersion,
          sessionOrigin: SessionOrigin.EDUCATOR_APP,
          userId,
        }

    const storedSessionInfo = await getStoredSessionInfo()

    if (!isSessionInfoEqual(storedSessionInfo, newSessionInfo)) {
      await updateSessionInfo({
        variables: {
          update: newSessionInfo,
        },
      })

      await storeSessionInfo(newSessionInfo)
    }
  }

  return {
    handleUpdateSessionInfo,
  }
}

export default useSessionInfo
