import React, { useEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import { JobsNavigatorParamList } from 'src/utils/types/navigationTypes'
import Booking from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Booking'
import ExpressSub from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Express'
import LongTermOffer from './Details/LongTermOffer'
import LongTermOfferList from './Lists/LongTermOfferList'
import mainHeaderConfig from 'src/utils/headerConfig/mainHeaderConfig'
import Order from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Order'
import Requests from './Lists/Requests'
import ExpressList from './Lists/ExpressList'
import stackNavigatorConfig from 'src/utils/headerConfig/stackNavigatorConfig'
import translations, { translate } from 'src/utils/translations/translations'
import { useNavigateToDeepLink } from 'src/hooks/useNavigateToDeepLink'
import { useSelector } from 'react-redux'
import { getDeepLinkParentLink } from 'src/store/app/selectors'

const Stack = createStackNavigator<JobsNavigatorParamList>()

const StackJobsContainer = () => {
  const deepLink = useSelector(getDeepLinkParentLink)
  const navigateToDeepLink = useNavigateToDeepLink()

  // Navigate to the stored deep link (if any) as this is the first screen after login
  useEffect(() => {
    setTimeout(navigateToDeepLink, 100, deepLink, 'parent')
  }, [deepLink])

  return (
    <Stack.Navigator {...stackNavigatorConfig()}>
      <Stack.Screen
        name="Requests"
        component={Requests}
        options={{ title: translate(translations.findJobs), headerShown: false }}
      />
      <Stack.Screen
        component={Booking}
        name="Booking"
        options={params => mainHeaderConfig(translations.jobOfferHeaderAvailable, params)}
      />
      <Stack.Screen
        component={ExpressSub}
        name="ExpressSub"
        options={params => mainHeaderConfig(translations.jobOfferHeaderAvailable, params)}
      />
      <Stack.Screen
        component={Order}
        name="Order"
        options={params => mainHeaderConfig(translations.jobOfferHeaderAvailable, params)}
      />
      <Stack.Screen
        component={LongTermOffer}
        name="Proposal"
        options={params => mainHeaderConfig(translations.longTermOfferApplicationHeader, params)}
      />
      <Stack.Screen
        component={LongTermOfferList}
        name="LongTermOfferList"
        options={params => mainHeaderConfig(translations.longTermOpportunities, params)}
      />
      <Stack.Screen
        component={ExpressList}
        name="ExpressOfferList"
        options={params => mainHeaderConfig(translations.availableExpressJobs, params)}
      />
    </Stack.Navigator>
  )
}

export default StackJobsContainer
