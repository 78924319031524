import { useLinkTo } from '@react-navigation/native'
import { setDeepLink } from 'src/store/app/actions'
import { useAppDispatch } from './reduxHooks'

/** Page to initially navigate to on deep link requests
 *
 * - Having this page in the history stack provides a page to go back to for
 *   screens outside of MainTabNavigator
 * - The actual deep link call happens in this page
 * */
export const DEFAULT_PAGE = '/MainTabNavigator/JobsNavigator'

/** Pattern for determining if the deep link refers to a screen inside
 *  MainTabNavigator
 *
 * If the regex matches, then we navigate to the initial page of that tab to
 * establish the root of the tab's navigation stack, which is needed for
 * handling back button navigations
 */
export const MAIN_TAB_NAVIGATOR_PATTERN = '^/MainTabNavigator/[a-zA-Z]+'

/** Prefix that needs to be added to deep links for using useLinkTo hook
 *
 * The screens we want to deep link to are all subpaths of this route, so this
 * is factored out to shorten the urls
 * */
export const DEEP_LINK_PATH_PREFIX = '/Main/PrivateRoutes/App'

/** Redirects user to a screen insider the app
 *
 * Works with web URLs, for example:
 * jobba.humly.io/MainTabNavigator/MenuNavigation/Support
 *
 * The URL has to be strcutured according to the nested navigators screen names
 *
 */
export function useNavigateToDeepLink() {
  const linkTo = useLinkTo()
  const dispatch = useAppDispatch()

  const navigateToDeepLink = (deepLink: string | undefined, type: 'parent' | 'nested') => {
    if (!deepLink) return

    dispatch(setDeepLink(type === 'nested' ? undefined : { parentLink: undefined }))
    // Clear the address bar as there's no sync with the address bar currently
    window.history?.replaceState(null, '', '/')
    linkTo(DEEP_LINK_PATH_PREFIX + deepLink)
  }

  return navigateToDeepLink
}
