import React, { useContext, useEffect } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Platform } from 'react-native'

import { bugsnagViewBreadcrumb, setBugsnagUser } from 'src/utils/bugsnag'
import { MixpanelContext } from './context/MixpanelContext'
import { PrivateRoutesParamList } from './utils/types/navigationTypes'
import { useAppState } from 'src/utils/AppStateEvent'
import MainComponent from 'src/scenes/Main'
import { useAppSelector } from './hooks/reduxHooks'

const Stack = createNativeStackNavigator<PrivateRoutesParamList>()

const isWeb = Platform.OS === 'web'

const options = {
  header: () => null,
  gestureEnabled: false,
}

const PrivateRoutes = () => {
  const reportingId = useAppSelector(state => state?.user?.attributes?.reportingId) || 'NO REPORTING ID'
  const { mixpanel, mixpanelUserSet } = useContext(MixpanelContext)

  useAppState({
    onBackground: () => mixpanel?.track('Session'),
    onForeground: () => (isWeb ? mixpanel?.time_event('Session') : mixpanel?.timeEvent('Session')),
  })

  const identifyUser = async () => await mixpanel?.identify(reportingId)

  useEffect(() => {
    setBugsnagUser(reportingId)
    bugsnagViewBreadcrumb('PrivateRoutes')
    identifyUser()
    mixpanelUserSet()
    isWeb ? mixpanel?.time_event('Session') : mixpanel?.timeEvent('Session')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack.Navigator>
      <Stack.Screen name="App" component={MainComponent} options={options} />
    </Stack.Navigator>
  )
}

export default PrivateRoutes
